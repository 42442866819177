var render = function () {
  var _vm$trip, _vm$trip2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "demo-inline-spacing-50 mt-25 pt-25",
    staticStyle: {
      "border-top": "1px dashed #bababa"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/seat.svg")
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatAvailable')) + ": "), _c('b', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.fareItem.seatAvailable) + " ")])])], 1), !['F1'].includes(_vm.trip.source) ? _c('div', {
    staticClass: "d-flex-center"
  }, [!((_vm$trip = _vm.trip) !== null && _vm$trip !== void 0 && _vm$trip.domestic) ? _c('div', {
    staticClass: "text-truncate d-flex-center"
  }, [['F1'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": !(_vm.fareItem.bagWeight && _vm.fareItem.bagWeightUnit)
    }
  }), _vm.fareItem.bagWeight && _vm.fareItem.bagWeightUnit ? _c('span', [_vm._v(" " + _vm._s(_vm.fareItem.bagWeight) + " " + _vm._s(_vm.fareItem.bagWeightUnit) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.bagNotInclude')) + " ")]), _vm.fareItem.bagWeight && _vm.fareItem.bagWeightUnit ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_vm._v(" " + _vm._s(_vm.fareItem.bagWeight) + " " + _vm._s(_vm.fareItem.bagWeightUnit) + " ")])]) : _vm._e()], 1) : _vm._e(), ['1G', 'AK', 'TH', '1A', '1B'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": !(_vm.fareItem.baggageAllowances && _vm.resolveBaggage1GAKComputed.checkinBag)
    }
  }), _vm.fareItem.baggageAllowances && _vm.resolveBaggage1GAKComputed.text ? _c('span', [_vm._v(" " + _vm._s(_vm.resolveBaggage1GAKComputed.text) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.bagNotInclude')) + " ")]), _vm.fareItem.baggageAllowances && _vm.resolveBaggage1GAKComputed.text ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_vm.resolveBaggage1GAKComputed.carryBag ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.resolveBaggage1GAKComputed.carryBag) + " ")]) : _vm._e(), _vm.resolveBaggage1GAKComputed.checkinBag ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.resolveBaggage1GAKComputed.checkinBag) + " ")]) : _vm._e()])]) : _vm._e()], 1) : _vm._e(), ['VN1A'].includes(_vm.trip.source) ? _c('div', {
    staticClass: "text-dark font-weight-bold d-flex"
  }, [['TAP'].includes(_vm.trip.clientId) ? [_c('LuggageIcon', {
    attrs: {
      "no-luggage": false
    }
  }), _c('BLink', {
    staticClass: "text-nowrap d-flex-center",
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleGotoLinkBaggage($event);
      }
    }
  }, [_c('span', {
    staticClass: "hover-underline"
  }, [_vm._v(" Xem hành lý ")]), _c('feather-icon', {
    staticClass: "text-info ml-25",
    attrs: {
      "icon": "InfoIcon",
      "size": "14"
    }
  })], 1)] : [_c('LuggageIcon', {
    attrs: {
      "no-luggage": !(_vm.fareItem.baggageAllowances && _vm.fareItem.baggageAllowances.length && _vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).checkinBag)
    }
  }), _c('span', {
    staticClass: "text-nowrap d-flex-center",
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_vm.fareItem.baggageAllowances && _vm.fareItem.baggageAllowances.length ? _c('span', [_vm._v(" " + _vm._s(_vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).text) + " ")]) : _vm._e(), _vm.fareItem.baggageAllowances && _vm.fareItem.baggageAllowances[0] && _vm.fareItem.baggageAllowances[0].descriptions && _vm.fareItem.baggageAllowances[0].descriptions.length ? _c('feather-icon', {
    staticClass: "text-info ml-25",
    attrs: {
      "icon": "InfoIcon",
      "size": "14"
    }
  }) : _vm._e()], 1), _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id),
      "boundary": "window"
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).carryBag ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).carryBag) + " ")]) : _vm._e(), _vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).checkinBag ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.resolveBaggageVN1A(_vm.fareItem.baggageAllowances).checkinBag) + " ")]) : _vm._e()])])]], 2) : _vm._e(), ['VU'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_vm._v(" " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e()]), _vm.fareRulesAirline('VU', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e(), ['VJ'].includes(_vm.trip.source) ? _c('span', [[_vm.trip.arrival.IATACode, _vm.trip.departure.IATACode].some(function (item) {
    return _vm.iataCodeVJ2.includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage && _vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareItem.groupClass).checkinBaggage) + " ")])])]) : _vm._e()], 1) : [_vm.trip.arrival.IATACode, _vm.trip.departure.IATACode].some(function (item) {
    return _vm.iataCodeVJ3.includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage && _vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareItem.groupClass).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _c('span', [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage && _vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_vm._v(" " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareItem.groupClass).checkinBaggage) + " ")])])]) : _vm._e()], 1)]) : _vm._e(), ['QH'].includes(_vm.trip.source) ? _c('span', [[_vm.trip.arrival.IATACode, _vm.trip.departure.IATACode].some(function (item) {
    return ['MEL', 'SYD', 'FRA', 'LGW'].includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_1', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e(), [_vm.trip.departure.IATACode].some(function (item) {
    return ['TPE', 'KHH', 'ICN', 'NRT'].includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_2', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e(), [_vm.trip.arrival.IATACode].some(function (item) {
    return ['SIN', 'BKK'].includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_3', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e(), [_vm.trip.arrival.IATACode].some(function (item) {
    return ['TSN', 'MFM', 'LJG', 'TPE', 'KHH', 'NRT', 'ICN'].includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_4', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e(), [_vm.trip.departure.IATACode].some(function (item) {
    return ['SIN', 'BKK'].includes(item);
  }) ? _c('span', {
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_5', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _vm._e()]) : _vm._e()]) : _c('div', {
    staticClass: "text-truncate d-flex-center",
    attrs: {
      "id": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_vm.trip.source === 'QH' && [_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode].includes('VCS') ? _c('b', [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage)
    }
  }), _c('span', [_vm._v(_vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).boardingBaggage))]), _vm._v(" " + _vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage && _vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _c('span', [_vm._v(_vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage))]), _vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).boardingBaggage && _vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareItem.fareType).checkinBaggage) + " ")])])]) : _vm._e()], 1) : _c('b', [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(" " + _vm._s(_vm.trip.source === 'QH' && _vm.fareItem.bookingClass === 'A' && _vm.fareItem.fareType === 'EconomySaver' && _vm.moment(_vm.trip.departure.at).isBetween('2024-01-24', '2024-02-26') ? '30KG' : _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage) + " ")]) : _vm._e(), _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage ? _c('b-tooltip', {
    attrs: {
      "triggers": "hover",
      "variant": "info",
      "placement": "top",
      "boundary": "window",
      "target": "baggage-".concat(_vm.trip.id, "-").concat(_vm.fareItem.id)
    }
  }, [_c('div', {
    staticClass: "text-heading-5 text-white"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).boardingBaggage) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.trip.source === 'QH' && _vm.fareItem.bookingClass === 'A' && _vm.fareItem.fareType === 'EconomySaver' && _vm.moment(_vm.trip.departure.at).isBetween('2024-01-24', '2024-02-26') ? '30KG' : _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).checkinBaggage) + " ")])])]) : _vm._e()], 1)])]) : _vm._e(), _c('div', {
    staticClass: "d-flex-center"
  }, [['1G', 'AK'].includes(_vm.trip.source) ? _c('div', [_vm.fareItem.cancelPenalties && _vm.fareItem.cancelPenalties.length && !_vm.fareItem.cancelPenalties[0].nonRefundable ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: "Ph\xED vui l\xF2ng li\xEAn h\u1EC7 booker",
      expression: "`Phí vui lòng liên hệ booker`",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.refundable')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'danger'
      },
      expression: "{ variant: 'danger' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": "".concat(_vm.$t("flight.fareRuleTimeStatus.noRefundable"))
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : ['1B'].includes(_vm.trip.source) ? _c('div', [_vm.fareItem.refundPenalties && _vm.fareItem.refundPenalties.length && !_vm.fareItem.refundPenalties[0].nonRefundable ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: "Ph\xED vui l\xF2ng li\xEAn h\u1EC7 booker",
      expression: "`Phí vui lòng liên hệ booker`",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.refundable')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'danger'
      },
      expression: "{ variant: 'danger' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": "".concat(_vm.$t("flight.fareRuleTimeStatus.noRefundable"))
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : ['VU'].includes(_vm.trip.source) ? _c('div', [(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, _vm.fareItem.fareType) ? !['', 'NONE', false].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, _vm.fareItem.fareType).refund) : !_vm.fareItem.noRefund) ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, _vm.fareItem.fareType) ? _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, _vm.fareItem.fareType).refund : _vm.$t('flight.fareRuleTimeStatus.refundable')
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.refundable')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "icon": "TrelloIcon",
      "badge-classes": "badge-info badge-glow",
      "title": _vm.$t("flight.fareRuleTimeStatus.noRefundable")
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : _vm.trip.domestic ? _c('div', [(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass) ? !['', 'NONE', false].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).refund) : !_vm.fareItem.noRefund) ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass) ? _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).refund : _vm.$t('flight.fareRuleTimeStatus.refundable')
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.trip.source !== 'VJ' ? _vm.$t('flight.fareRules.refundable') : _vm.$t('flight.fareRules.refundableIdentify')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "icon": "TrelloIcon",
      "badge-classes": "badge-info badge-glow",
      "title": _vm.$t("flight.fareRuleTimeStatus.noRefundable")
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex-center"
  }, [['1G', 'AK', '1B'].includes(_vm.trip.source) ? _c('div', [_vm.fareItem.changePenalties && _vm.fareItem.changePenalties.length && !_vm.fareItem.changePenalties[0].nonRefundable ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: "".concat(_vm.$t('flight.fareRules.contactBooker')),
      expression: "`${$t('flight.fareRules.contactBooker')}`",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.reschedulable')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'danger'
      },
      expression: "{ variant: 'danger' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": _vm.$t("flight.fareRuleTimeStatus.noReschedulable")
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noReschedulable')) + " ")])], 1)]) : _vm.trip.domestic ? _c('div', [!['NONE', false, ''].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).reschedule) ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "badge-classes": "badge-info badge-glow",
      "title": _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareItem.fareType : _vm.fareItem.groupClass).reschedule
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.reschedulable')) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "badge-classes": "badge-info badge-glow",
      "title": _vm.$t("flight.fareRuleTimeStatus.noReschedulable")
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noReschedulable')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex-center"
  }, [_vm.trip.source === '1G' ? _c('div', [_vm.fareItem.noshowPenalties && _vm.fareItem.noshowPenalties.length && !_vm.fareItem.noshowPenalties[0].nonRefundable ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'success'
      },
      expression: "{ variant: 'success' }",
      modifiers: {
        "hover": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: "".concat(_vm.$t('flight.fareRules.contactBooker')),
      expression: "`${$t('flight.fareRules.contactBooker')}`",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("flight.fareRuleTimeStatus.noshow")) + " ")])], 1) : _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        variant: 'danger'
      },
      expression: "{ variant: 'danger' }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": _vm.$t("flight.fareRuleTimeStatus.notNoshow")
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t("flight.fareRuleTimeStatus.notNoshow")) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex-center justify-content-end flex-1 mr-0"
  }, [['VN1A', '1G', 'VU', 'QH', 'VJ', 'AK', 'TH', '1A'].includes(_vm.trip.source) || _vm.trip.source === 'F1' && _vm.airlineHaveLinkFareRule.includes(_vm.trip.airline) ? _c('b-button', {
    staticClass: "px-75 py-50 rounded-lg text-nowrap",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.onFareRuleHandle(_vm.trip, _vm.fareItem);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRule')) + " ")]) : _vm._e(), _c('ModalFareRules', {
    attrs: {
      "id": "".concat(_vm.fareItem.id, "-").concat(_vm.trip.id),
      "trip": _vm.trip,
      "fare-option": _vm.fareItem,
      "fare-value": (_vm$trip2 = _vm.trip) === null || _vm$trip2 === void 0 ? void 0 : _vm$trip2.fareValue
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }